
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/layout/fonts/va9B4kDNxMZdWfMOD5VnPKreSBf6Xl7Gl3LX.woff2) format('woff2');
    src: url(/layout/fonts/va9B4kDNxMZdWfMOD5VnPKruQg.woff) format('woff');
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/layout/fonts/va9E4kDNxMZdWfMOD5VvmYjLazX3dGTP.woff2) format('woff2');
    src: url(/layout/fonts/va9E4kDNxMZdWfMOD5Vfkw.woff) format('woff');
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/layout/fonts/va9B4kDNxMZdWfMOD5VnZKveSBf6Xl7Gl3LX.woff2) format('woff2');
    src: url(/layout/fonts/va9B4kDNxMZdWfMOD5VnZKvuQg.woff) format('woff');
}


@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/layout/fonts/va9B4kDNxMZdWfMOD5VnLK3eSBf6Xl7Gl3LX.woff2) format('woff2');
    src: url(/layout/fonts/va9B4kDNxMZdWfMOD5VnLK3uQg.woff) format('woff');
}
